import { render, staticRenderFns } from "./userCaseInfo.vue?vue&type=template&id=0b193fcb&scoped=true&"
import script from "./userCaseInfo.vue?vue&type=script&lang=js&"
export * from "./userCaseInfo.vue?vue&type=script&lang=js&"
import style0 from "./userCaseInfo.vue?vue&type=style&index=0&id=0b193fcb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b193fcb",
  null
  
)

export default component.exports